<template>
  <div id="Top3Panel" class="column-row column-row-2" style="margin-bottom: 80px; height: 294px;">
    <div class="column column-large first last">
      <div class="column-top"></div>
      <div id="ContentContainer" class="column-content">
        <div class="news-network-row negative-row">
          <div v-for="story in stories" v-bind:key="story.title" class="news-network-column">
            <router-link :to="story.href" class="rounded">
              <img style="width: 200px; height: 150px;" :src="story.image" class="rounded" :alt="story.title">
            </router-link>
            <h2>
              <router-link :to="story.href">{{story.title}}</router-link>
            </h2>
            <p>{{story.intro}}</p>
            <p><router-link :to="story.href" class="linkArrow">Read More</router-link></p>
            <div class="column-round news-tout-date small" style="margin-bottom: 10px;">
              <b><i></i></b><i><b></b></i><em></em>
              <div class="column-round-body">
                Posted {{story.date}}
                <span v-if="story.category">
                  -&nbsp;<router-link :to="story.category.href">{{story.category.title}}</router-link>
                </span>
                by {{story.author}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core"

  export default defineComponent({
    data() {
      return {}
    },
    props: ["stories"]
  });
</script>

<style lang="scss">
.news-network-column {
  img {
    width: 200px;
    height: 150px;
    display: block;
    background-color: #000;
  }
}
</style>
