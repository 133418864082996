<template>
  <div>
    <top-story :story="topStory"></top-story>
    <top3-panel :stories="top3Stories"></top3-panel>
  </div>
</template>

<script lang="ts">
  import Top3Panel from '@/components/news-network/Top3Panel.vue';
  import TopStory from '@/components/news-network/TopStory.vue';
  import { STORIES, Story } from '@/stories/index';

  interface TopStoryPanelData {
    topStory: Story,
    topStoryId: number,
    top3StoryIds: number[],
  }

  export default {
    components: {
      Top3Panel,
      TopStory,
    },
    computed: {
      topStory(this: TopStoryPanelData) {
        return STORIES[this.topStoryId];
      },
      top3Stories(this: TopStoryPanelData) {
        return this.top3StoryIds.map((id) => STORIES[id]);
      }
    },
    data() {
      return {
        topStoryId: 336138,
        top3StoryIds: [335660, 335280, 335037],
      }
    }
  }
</script>
