<template>
  <router-view></router-view>
</template>

<script lang="ts">

export default {
  components: {}
}
</script>

<style lang="scss">
  .news-network-row {
    overflow: hidden;
    height: 100%;
  }

  .news-network-column {
    float: left;
    width: 200px;
    height: 100%;
    margin: 0 13px 0 12px;
    position: relative;
  }
</style>
