<template>
  <div class="column-row column-row-2" style="margin-bottom: 80px; height: 229px;">
    <div class="column column-large first last">
      <div class="column-top"></div>
      <div class="column-content news-network-landingpage">
        <div class="negative-row">
          <router-link :to="story.href" class="rounded">
            <img class="rounded" style="width: 440px; height: 261px;" :src="story.imageLarge">
          </router-link>
          <div id="newsNetworkTopStory" class="news-network-landingpage-content">
            <h2><router-link :to="story.href">{{story.title}}</router-link></h2>
            <p>{{story.intro}}</p>
            <p><router-link :to="story.href" class="linkArrow">Read More</router-link></p>
            <div class="column-round news-tout-date small" style="margin-bottom: 0px; position: absolute; bottom: 14px;">
              <b><i></i></b><i><b></b></i><em></em>
              <div class="column-round-body">
                Posted {{story.date}}
                 -  <router-link :to="story.category.href">{{story.category.title}}</router-link>
                by {{story.author}}
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["story"]
  }
</script>
