<template>
  <div id="mainColumn1">
    <top-story-panel></top-story-panel>
    <story-list-panel :stories="stories"></story-list-panel>
  </div>
</template>

<script>
import TopStoryPanel from '@/components/news-network/TopStoryPanel.vue';
import StoryListPanel from '@/components/news-network/StoryListPanel.vue';
import { STORIES } from '@/stories/index.ts';

export default {
  computed: {
    stories() {
      return this.storyIds.map((id) => STORIES[id]);
    }
  },
  data() {
    return {
      storyIds: [334961, 334657, 334660, 334977, 334978, 334653],
    }
  },
  components: {
    TopStoryPanel,
    StoryListPanel,
  }
}
</script>
